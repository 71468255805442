import { CurrencyCode } from "../../utils/currency.types";
import { extantMap } from "../../utils/objects";
import { boolComparator, makeOrderedListComparator, multiComparator, numComparator } from "../../utils/sort";
import {
  EditionMeta,
  EntitlementIntegrations,
  EntitlementMeta,
  EntitlementSupport,
  EntitlementType,
  ReclaimEdition,
  SubscriptionFrequencyStr,
  SupportedCurrency,
} from "./Team.types";

export const SUPPORTED_CURRENCIES_LIST: readonly SupportedCurrency[] = ["USD", "EUR"];
export const SUPPORTED_CURRENCY_MAP = extantMap<CurrencyCode>(SUPPORTED_CURRENCIES_LIST);

export const integrationEntitlementComparator = makeOrderedListComparator<EntitlementIntegrations>([
  "ESSENTIALS",
  "LEGACY_PRO",
  "PREMIUM",
]);

export const EDITION_ORDER: readonly ReclaimEdition[] = Object.freeze([
  "NONE",
  "ASSISTANT",
  "LITE",
  "PRO",
  "STARTER",
  "TEAM",
  "BUSINESS",
  "REWARD",
  "ENTERPRISE",
  "TRIAL",
  "LEGACY_PRO_TRIAL",
  "LEGACY_TEAM_TRIAL",
  "TRIAL_BUSINESS",
  "TEAM_REWARD",
]);

export const EDITION_META: Readonly<Record<ReclaimEdition, EditionMeta>> = {
  ASSISTANT: { label: "Free", isLegacy: true, isNonPaid: true },
  LEGACY_PRO_TRIAL: { label: "Team", isTrial: true, isLegacy: true },
  LEGACY_TEAM_TRIAL: { label: "Team", isTrial: true, isLegacy: true },
  NONE: { label: "", isLegacy: true },
  PRO: { label: "Pro", isLegacy: true },
  REWARD: { label: "Team", isLegacy: true, isReward: true },
  TEAM: { label: "Team", isLegacy: true },
  TRIAL: { label: "Team", isTrial: true, isLegacy: true },
  TRIAL_BUSINESS: { label: "Business", isTrial: true },
  TRIAL_BUSINESS_12W: { label: "Business", isTrial: true },
  TRIAL_BUSINESS_FOR_LEGACY_PLANS: { label: "Business", isTrial: true },
  TEAM_REWARD: { label: "Business", isReward: true },

  LITE: {
    label: "Lite",
    description: "Lite plans are great for those who are new to Reclaim and want to learn the basics for free.",
    isNonPaid: true,
  },
  STARTER: {
    label: "Starter",
    description: "Starter plans are great for those working alone or on a small team with moderate needs.",
  },
  BUSINESS: {
    label: "Business",
    description:
      "Business plans are good for larger teams and companies who want to use Reclaim across the organization.",
  },
  ENTERPRISE: {
    label: "Enterprise",
    description: "Enterprise plans are for the largest teams and companies.  Contact us to learn more.",
  },
};

export const ENTITLEMENT_META: Readonly<{ [ENTITLEMENT in EntitlementType]: EntitlementMeta<ENTITLEMENT> }> = {
  TEAM_SIZE: {
    label: "Max Users in Team",
    description: "The maximum number of users you can have in your Reclaim team.",
    comparator: (a, b) => multiComparator([a?.max, b?.max, numComparator], [a?.min, b?.min, numComparator]),
  },
  SCHEDULER_WEEKS: {
    label: "Scheduler range",
    comparator: numComparator,
  },
  MAX_TASKS: {
    label: "Tasks",
    comparator: numComparator,
  },
  MAX_CALENDARS: {
    label: "Connected Calendars",
    comparator: numComparator,
  },
  MAX_SYNCS: {
    label: "Calendar Syncs",
    comparator: numComparator,
  },
  MAX_HABITS: {
    label: "Habits",
    comparator: numComparator,
  },
  MAX_CUSTOM_TIME_SCHEMES: {
    label: "Custom hours",
    comparator: numComparator,
  },
  MAX_SCHEDULING_LINKS: {
    label: "Scheduling Links",
    comparator: numComparator,
    displayOverrideValues: {
      LITE: 1,
      PRO: 1,
      TEAM: 1,
      STARTER: 3,
      BUSINESS: Infinity,
      ENTERPRISE: Infinity,
    },
  },
  MAX_1_ON_1_ORGANIZE: {
    label: "Organize Smart 1:1s",
    comparator: numComparator,
  },
  MAX_1_ON_1_ATTEND: {
    label: "Attend Smart 1:1s",
    comparator: numComparator,
  },
  SUPPORT: {
    label: "Support",
    comparator: makeOrderedListComparator<EntitlementSupport>(["COMMUNITY", "BASIC", "PRIORITY"]),
  },
  SSO: {
    label: "SSO/Domain capture",
    comparator: boolComparator,
  },
  CUSTOM_SLACK_STATUS: {
    label: "Custom Slack Status Integration",
    comparator: boolComparator,
  },

  // custom blocking
  CUSTOM_BLOCKING: {
    label: "Time blocking",
    comparator: boolComparator,
  },

  CUSTOM_BLOCKING_CALENDAR_SYNC: {
    label: "Calendar Sync blocking",
    comparator: boolComparator,
  },
  CUSTOM_BLOCKING_DECOMPRESSION: {
    label: "Decompression",
    comparator: boolComparator,
  },
  CUSTOM_BLOCKING_HABITS: {
    label: "Habit blocking",
    comparator: boolComparator,
  },

  // integrations
  INTEGRATIONS: {
    label: "Integrations",
    comparator: integrationEntitlementComparator,
  },

  INTEGRATION_SLACK: {
    label: "Slack Integration",
    comparator: boolComparator,
  },
  INTEGRATION_RAYCAST: {
    label: "Raycast Integration",
    comparator: boolComparator,
  },
  INTEGRATION_CLICKUP: {
    label: "Clickup Integration",
    comparator: boolComparator,
  },
  INTEGRATION_ZOOM: {
    label: "Zoom Integration",
    comparator: boolComparator,
  },
  INTEGRATION_GOOGLE_TASKS: {
    label: "Google Tasks Integration",
    comparator: boolComparator,
  },
  INTEGRATION_JIRA: {
    label: "Jira Integration",
    comparator: boolComparator,
  },
  INTEGRATION_ASANA: {
    label: "Asana Integration",
    comparator: boolComparator,
  },
  INTEGRATION_TODOIST: {
    label: "Todoist Integration",
    comparator: boolComparator,
  },
  INTEGRATION_LINEAR: {
    label: "Linear Integration",
    comparator: boolComparator,
  },
  INTEGRATION_GOOGLE_ADD_ON: {
    label: "Linear Integration",
    comparator: boolComparator,
  },
  INTEGRATION_MONDAY: {
    label: "Linear Integration",
    comparator: boolComparator,
  },
  INTEGRATION_OFFICE_365: {
    label: "Linear Integration",
    comparator: boolComparator,
  },
  INTEGRATION_TRELLO: {
    label: "Linear Integration",
    comparator: boolComparator,
  },
  DERIVATIVE_SCHEDULING_LINKS: {
    label: "Scheduling Links custom links",
    comparator: boolComparator,
  },
  TEAM_ANALYTICS: {
    label: "Team Analytics",
    comparator: boolComparator,
  }
};

export const SUB_FREQUENCY_ORDER: readonly SubscriptionFrequencyStr[] = Object.freeze(["MONTH", "YEAR"]);
export const SUB_FREQUENCY_META: Readonly<Record<SubscriptionFrequencyStr, { label: string }>> = Object.freeze({
  MONTH: { label: "month" },
  YEAR: { label: "year" },
});
